import { ReactNode, useCallback } from "react";
import { createPortal } from "react-dom";

import { animated, useSpring } from "@react-spring/web";
import { get } from "lodash-es";
import styled, { css } from "styled-components";

import { Button, ButtonVariants } from "../Button";
import { CircularButton } from "../CircularButton";
import { CloseIcon } from "../Icons";
import { Paper } from "../Paper";
import { Typography } from "../Typography";
import { PaletteVariants } from "../styles";

const modalSizes = {
  lg: "600px",
  md: "480px",
  sm: "420px",
};

type ModalSizeType = keyof typeof modalSizes;

export type ModalProps = {
  isOpen: boolean;
  size?: ModalSizeType;
  title?: string;
  description?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;

  cancelText?: string;
  onCancel?: () => void;
  cancelButtonVariant?: ButtonVariants;
  shouldCloseOnCancel?: boolean;

  disableConfirm?: boolean;
  confirmText?: string;
  onConfirm?: () => void;
  confirmButtonVariant?: ButtonVariants;

  isSubmitting?: boolean;

  shouldContentHavePadding?: boolean;
  topBarBgColor?: PaletteVariants;
};

export function Modal({
  children,
  size = "md",
  isOpen,
  title,
  description,
  cancelText,
  confirmText,
  disableConfirm,
  isSubmitting,
  confirmButtonVariant,
  cancelButtonVariant = "outlined",
  onClose,
  onCancel,
  shouldCloseOnCancel = true,
  onConfirm,
  shouldContentHavePadding = true,
  topBarBgColor,
}: ModalProps) {
  const styles = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
    opacity: isOpen ? 1 : 0,
    config: { tension: 210, friction: 20, duration: 300 },
  });

  const overlayStyles = useSpring({
    opacity: isOpen ? 0.5 : 0,
    config: { tension: 210, friction: 20, duration: 200 },
  });

  const hasFooter = Boolean(onCancel ?? onConfirm);

  const handleCancel = useCallback(() => {
    onCancel?.();
    shouldCloseOnCancel && onClose?.();
  }, [onCancel, onClose, shouldCloseOnCancel]);

  if (!isOpen) return null;

  return createPortal(
    <Root data-testid="modal-root">
      <animated.div style={styles}>
        <StyledPaper background="background.white" padding="none" shadow="md">
          <ModalBox size={size} role="dialog">
            <Header $topBarBgColor={topBarBgColor}>
              {onClose && (
                <CircularButton
                  icon={<CloseIcon />}
                  onClick={onClose}
                  variant="outlined"
                  ariaLabel="Close Modal"
                  size="md"
                />
              )}
            </Header>
            <Content $padding={shouldContentHavePadding}>
              {title && (
                <Typography
                  variant="h2"
                  align="center"
                  fullWidth
                  marginBottom="sm"
                >
                  {title}
                </Typography>
              )}
              {description && (
                <Typography
                  variant="bodyLg"
                  align="center"
                  fullWidth
                  marginBottom="sm"
                >
                  {description}
                </Typography>
              )}
              {children && <div>{children}</div>}
            </Content>
            {hasFooter && (
              <Footer>
                {onCancel && (
                  <Button variant={cancelButtonVariant} onClick={handleCancel}>
                    {cancelText}
                  </Button>
                )}
                {onConfirm && (
                  <Button
                    onClick={onConfirm}
                    disabled={disableConfirm}
                    isLoading={isSubmitting}
                    variant={confirmButtonVariant}
                  >
                    {confirmText}
                  </Button>
                )}
              </Footer>
            )}
          </ModalBox>
        </StyledPaper>
      </animated.div>
      <Overlay onClick={onClose} style={overlayStyles} />
    </Root>,
    document.body,
  );
}

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.modal};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      align-items: flex-end;
    `)}
  body:has(&) {
    overflow: hidden;
  }
`;

const Overlay = animated(styled.div`
  background-color: ${({ theme }) => theme.palette.primary1.main};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: translateY(0%);
`);

const ModalBox = styled.div<{
  size?: ModalSizeType;
}>`
  width: ${({ size = "md" }) => modalSizes[size]};
  /* Set the max height to be the full viewport height minus extra spacing from the theme. */
  max-height: ${({ theme }) => `calc(100vh - ${theme.spacing.xl})`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 100vw;
    `)}
`;

const Header = styled.div<{ $topBarBgColor?: PaletteVariants }>`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  background-color: ${({ theme, $topBarBgColor }) =>
    get(theme.palette, $topBarBgColor ?? "")};
`;

const Footer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.palette.primary1.lighter};
  padding: ${({ theme }) => theme.spacing.md};
  gap: ${({ theme }) => theme.spacing.sm};
  justify-content: space-around;
  width: 100%;

  & > * {
    flex: 1 1 content;
  }

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      flex-direction: column;
    `)}
`;

const Content = styled.div<{ $padding: boolean }>`
  padding: ${({ theme, $padding }) =>
    $padding ? `${theme.spacing.md} ${theme.spacing.lg}` : 0};
  flex: 1;
  overflow-y: auto;
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    `)}
`;
